import React, { useState, useEffect } from "react";
import axios from "axios";
import env from "react-dotenv";
import { Pane, Paragraph, Button, Dialog } from 'evergreen-ui';

const Download = (props) => {
	let files = props.files.filter(n => n);

	const downloadFiles = async (files) => {
		if (props.mode === "local") {
			props.handleSetDownloading(true);
			for (let i=0; i<files.length; i++) {
				if (files[i]) {
					// get presigned download url
					let filename = files[i].replace(/^[^\/]+\//, "");
					const url_res = await axios.post(`${env.BASE_URL}/api/books/none/get_presigned_url/`, {"key": files[i], "method": "get_object"});
					// start download
					const response = await fetch(url_res.data.url);
		      const blob = await response.blob();
		      const link = document.createElement('a');
		      link.href = URL.createObjectURL(blob);
		      link.download = filename;
		      link.click();
		      URL.revokeObjectURL(link.href);
				}
			}
			props.handleSetDownloading(false);
		}
	}

	let label = props.mode === "local" ? "Start Download" : "Okay";
  
  return (
    <Pane 
    >
    	<Dialog
        isShown={props.show}
        title="Downloading:"
        onCloseComplete={() => props.handleSetShown(false)}
        confirmLabel={label}
        onConfirm={(close) => {
        	downloadFiles(props.files);
        	close();
        }}
      >
	      {files.map((file, i) => <FileDownload key={`download${i}`} file={file} mode={props.mode} />)}
	      <Paragraph size={300} marginTop={12} fontStyle="italic">Large files may take a moment to download, please be patient after clicking {label}.</Paragraph>
	    </Dialog>
    </Pane>
  );
}

const FileDownload = (props) => {

	let filename = props.file.replace(/^[^\/]+\//, "");

	return (
		<Pane display="flex" flexDirection="row" alignItems="center">
		  <Paragraph size={300} marginRight={8}>{props.file}</Paragraph>
		  {props.mode === "dropbox" && (
		    <DropboxDownload files={[props.file]} filename={filename} />
		  )}
		</Pane>
	);
}

const DropboxDownload = (props) => {
	const [saverfiles, setSaverfiles] = useState([]);
  const [load, setLoad] = useState(true);

  const isDropboxReady = () => {
    console.log("trying to load");
    return !!window.Dropbox;
  }

  const onSave = () => {
    if (!isDropboxReady() || props.disabled) {
      return null;
    }
  }

  useEffect(() => {
    if (load === true && props.files.length === 1) {
      async function fetchUrls() {
        let tmpDownloads = [];
      	console.log(props.filename);
      	const res = await axios.post(`${env.BASE_URL}/api/books/none/get_presigned_url/`, {"key": props.files[0], "method": "get_object"});
      	if (res && res.status === 200) {
          tmpDownloads.push({filename: props.filename, url: res.data.url});
        }
        console.log(tmpDownloads);
        setSaverfiles(tmpDownloads);
        if (isDropboxReady()) {
		      var button = window.Dropbox.createSaveButton({files: tmpDownloads});
		      var mySaver = document.getElementById("saver"+props.filename);
		      if (mySaver) {
		      	mySaver.replaceChildren(button);
		      }
		    }
      }
      fetchUrls();
      setLoad(false);
    }
  }, [saverfiles, load]);

	return (
		<Pane>
			<div onClick={e => onSave()} id={`saver${props.filename}`}></div>
	  </Pane>
	);
}

export default Download;
