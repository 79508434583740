import React from 'react';
import { Pane, Paragraph, FullCircleIcon, CrossIcon, TickCircleIcon } from 'evergreen-ui';

const Status = (props) => {

    console.log(props.items);

    return (
    	<Pane padding={12}>
            {props.items.length > 0 && (
                <Paragraph>Converting {props.items.length} books:</Paragraph>
            )}
            {props.items.map((item) => <StatusItem key={`item${item.conversion_id}${item.eisbn}`} item={item} />)}
        </Pane>
    )
}

const StatusItem = (props) => {
    return (
        <Pane>
            <Paragraph size="small">
                {props.item.status === "SUCCESS" ? (
                    <TickCircleIcon color="success" size={8} marginRight={8} />
                ) : props.item.status === "FAIL" ? (
                    <CrossIcon color="danger" size={12} marginRight={8} />
                ) : (
                    <FullCircleIcon color="warning" size={8} marginRight={8} />
                )}
                {props.item.title_long} ({props.item.eisbn})
            </Paragraph>
        </Pane>
    )
}

export default Status;