import React from "react";
import { Pane, TextInput, Checkbox } from 'evergreen-ui';

const MetadataForm = (props) => {

  return (
    <Pane 
      display="flex" 
      flexDirection="column" 
      alignItems="flex-end" 
      justifyContent="flex-start" 
      border="default"
      padding={12}
    >
      <TextInput 
        width="100%"
        marginBottom={8}
        placeholder="Title Yomigana"
        value={props.metadata.title_long} 
        onChange={e => {
          props.updateMetadata({title_long: e.target.value});
        }}
      />
      <TextInput 
        width="100%"
        placeholder="Author Yomigana"
        value={props.metadata.author_yomigana} 
        onChange={e => {
          props.updateMetadata({author_yomigana: e.target.value});
        }}
      />
      <Pane 
        display="flex" 
        flexDirection="row" 
        justifyContent="space-between" 
        alignItems="flex-start"
        marginTop={8}
      >
        <TextInput 
          isInvalid={props.metadata.eisbn.length > 13}
          width="49%"
          placeholder="eISBN (REQUIRED)"
          value={props.metadata.eisbn} 
          onChange={e => {
            props.updateMetadata({eisbn: e.target.value});
          }}
        />
        <TextInput 
          isInvalid={props.metadata.pisbn.length > 13}
          width="49%"
          placeholder="pISBN"
          value={props.metadata.pisbn} 
          onChange={e => {
            props.updateMetadata({pisbn: e.target.value});
          }}
        />
      </Pane>
      <Checkbox 
        marginTop={8}
        marginBottom={0}
        label="Reads LTR?" 
        checked={props.metadata.ltr} 
        onChange={e => {
          props.updateMetadata({ltr: e.target.checked});
        }}
      />
    </Pane>
  );
}

export default MetadataForm;