import React, { useState } from "react";
import axios from "axios";
import env from "react-dotenv";
import { Pane, Paragraph, Button, Dialog, Select, TextInput, Text } from 'evergreen-ui';
import DropFile from "./DropFile";

const CustomPages = (props) => {
	const [mode, setMode] = useState('replace');
	const [page, setPage] = useState("");
	const [file, setFile] = useState([]);
	const [position, setPosition] = useState("before");

	const addCustomPage = () => {
		if (page && file) {
			let item = {
				mode,
				page,
				file,
				position
			};
			props.handleAddCustomPage(item);
			// reset
			setMode('replace');
			setPage("");
			setFile([]);
			setPosition("before");
		}
	}
  
  return (
    <Pane 
    >
    	<Dialog
    		width={640}
        isShown={props.show}
        title="Add a Custom Page:"
        onCloseComplete={() => props.handleSetShown(false)}
        confirmLabel="OK"
        onConfirm={(close) => {
        	addCustomPage();
        	close();
        }}
      >
      	<Pane display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
		      <Select value={mode} onChange={event => setMode(event.target.value)}>
			      <option value="replace">
			        Replace
			      </option>
			      <option value="insert">Insert</option>
			    </Select>
			    {mode === "replace" ? (
			    	<Replace page={page} file={file} setPage={setPage} setFile={setFile} />
			    ) : (
			    	<Insert page={page} file={file} setPage={setPage} setFile={setFile} setPosition={setPosition} position={position} />
			    )}
			  </Pane>
	    </Dialog>
    </Pane>
  );
}

const Replace = (props) => {

	return (
		<Pane display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
			<Paragraph marginX={12}>page</Paragraph>
			<TextInput width={60} onChange={e => props.setPage(e.target.value)} value={props.page} />
			<Paragraph marginX={12}>with</Paragraph>
			<DropFile label="Drop a PDF..." type="pdf" handleFileData={props.setFile} pdfFiles={props.file} compact={true} />
		</Pane>
	)
}

const Insert = (props) => {

	return (
		<Pane display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
		  <DropFile label="Drop a PDF..." type="pdf" handleFileData={props.setFile} pdfFiles={props.file} compact={true} />
		  <Select value={props.position} onChange={event => props.setPosition(event.target.value)}>
	      <option value="before">
	        Before
	      </option>
	      <option value="after">After</option>
	    </Select>
			<Paragraph marginX={12}>page</Paragraph>
			<TextInput width={60} onChange={e => props.setPage(e.target.value)} value={props.page} />			
		</Pane>
	)
}

export default CustomPages;
