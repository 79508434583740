import React, { useState, useEffect } from 'react';
import { Pane, Heading, PlusIcon, MinusIcon } from 'evergreen-ui';

const ExpandablePane = (props) => {
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
	  setExpanded(props.expanded);
	}, [props.expanded]);

    return (
    	<Pane
    		className="expandablepane"
	    	width="800px"
	    	padding={0}
	    	border="default"
	    	height={expanded ? "auto" : 48}
	    	marginBottom={12}
    	>
    		<Heading 
    			onClick={e => setExpanded(!expanded)} 
    			marginBottom={0} 
    			paddingLeft={12} 
    			lineHeight="48px"
    			display="flex"
    			flexDirection="row"
    			alignItems="center"
    		>
    			{expanded ? (
    				<MinusIcon onClick={e => setExpanded(!expanded)} marginRight={8} size={12} />
    			) : (
    				<PlusIcon onClick={e => setExpanded(!expanded)} marginRight={8} size={12} />
    			)}
    			{props.label}
    		</Heading>
    		{props.content}
    	</Pane>
    )
}

export default ExpandablePane;
