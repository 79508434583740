import React, { useState } from 'react';
import axios from "axios";
import { Pane, Paragraph, Button } from 'evergreen-ui';
import ExpandablePane from "./ExpandablePane";
import DropFile from "./DropFile";
import ConversionSettings from "./ConversionSettings";
import Note from "./Note";
import env from "react-dotenv";

const BatchUpload = (props) => {

    return (
    	<ExpandablePane 
    		expanded={false} 
    		label="Batch Upload" 
    		content={<BatchUploadInner handleTriggerConversion={props.handleTriggerConversion} />} 
    	/>
    )
}

const BatchUploadInner = (props) => {
	const [data, setData] = useState(null);
	const [pdfFiles, setPdfFiles] = useState([]);
	const [note, setNote] = useState("");
	const [headers, setHeaders] = useState({
		"title": 0,
		"author": 0,
		"lang": 0,
		"eisbn": 0,
		"pisbn": 0,
		"ltr": 0
	});
	const [conversionSettings, setConversionSettings] = useState({
    pdfOnly: false, 
    epubOnly: false, 
    coverOnly: false, 
    noCover: false
  });

	const updateConversionSettings = (updated) => {
		setConversionSettings({...conversionSettings, ...updated});
		console.log(conversionSettings);
	}

	// TRIGGERED CONVERSION HERE

	const processBooksMetadata = (data) => {
		if (data && data.length > 0) {
			// get the header rows to determine which data we need
			setData(data);
			const header_data = {
				"title": ["Title Long"],
				"author": ["Author Yomigana"],
				"lang": ["Language"],
				"eisbn": ["eISBN"],
				"pisbn": ["Print ISBN"],
				"ltr": ["Reads LTR", "Read LTR"]
			}
			const header_indices = {...headers};
			for (let i=0; i < data[0].length; i++) {
				for (let j in header_data) {
					if (header_data[j].indexOf(data[0][i]) > -1) {
						header_indices[j] = i;
					}
				}
			}
			setHeaders(header_indices);
		}
	}

	const processBookFiles = (inputPdfFiles) => {
		console.log(inputPdfFiles);
		setPdfFiles(inputPdfFiles);
	}

	const reconcileFilesAndData = (item, pdfFiles) => {
		for (let i=0; i < pdfFiles.length; i++) {
			let file = pdfFiles[i];
			// first look for an interior file
			let sanitizedName = file.name.replace(/\D/g, "");
			if (
				(sanitizedName.indexOf(item["eisbn"]) > -1 || sanitizedName.indexOf(item["pisbn"]) > -1) && 
				file.name.indexOf("_WEB.pdf") < 0
			) {
				if (file.name.search(/[Cc][Oo][Vv][Ee][Rr]/) > -1) {
					item["cover"] = file.path;
					item["cover_file"] = file;
				} else if (file.name.search(/[Cc][Oo][Pp][Yy][Rr][Ii][Gg][Hh][Tt]/) > -1) {
					item["copyright"] = file.path;
					item["copyright_file"] = file;
				} else {
					item["interior"] = file.path;
					item["interior_file"] = file;
				}
			}
		}
		return item;
	}

	const runConversion = async () => {
		// start at 1 to skip the header row...
		for (let i=1; i < data.length; i++) {
			const row = data[i];
			const item = {
				"title_long": row[headers["title"]],
				"author_yomigana": row[headers["author"]],
				"lang": row[headers["lang"]],
				"eisbn": row[headers["eisbn"]],
				"pisbn": row[headers["pisbn"]],
				"ltr": row[headers["ltr"]]
			}
			// only create/update metadata if a matching PDF file is found
			const reconciledItem = reconcileFilesAndData(item, pdfFiles);
			if (reconciledItem.hasOwnProperty("interior") || conversionSettings.coverOnly === true) {
				props.handleTriggerConversion(reconciledItem, note, conversionSettings);
			}
		}
		// Reset state to allow new uploads
		setData(null);
		setPdfFiles([]);
		setNote("");
		setHeaders({
			"title": 0,
			"author": 0,
			"lang": 0,
			"eisbn": 0,
			"pisbn": 0,
			"ltr": 0
		});
		setConversionSettings({
	    pdfOnly: false, 
	    epubOnly: false, 
	    coverOnly: false, 
	    noCover: false
	  });
	}

  return (
  	<Pane
  		display="flex"
  		flex-direction="row"
  		justifyContent="flex-start"
  		alignItems="flex-start"
  		background="tint1"
  		padding={12}
  	>
  		<DropFile label="Drag a metadata .xlsx file here" type="xlsx" handleFileData={processBooksMetadata} data={data} />
  		<DropFile label="Drag the folder containing your PDF files here" type="pdf" handleFileData={processBookFiles} pdfFiles={pdfFiles} />
  		<Pane>
  			<ConversionSettings updateConversionSettings={updateConversionSettings} />
  			<Note value={note} handleChange={setNote} handleRemoveNote={e => setNote("")} />
  			<Button onClick={e => runConversion()} marginTop={12} appearance="primary" intent="success" size="large">Go</Button>
  		</Pane>
  	</Pane>
  )
}

export default BatchUpload;

// TO DO: 
// 1. Deploy to KOD hosting platform and get everything running
// (Lambda: update conversion status on completion:)
// 2. Generate API key in prod admin
// 3. Add api key to lambda .env
// 4. Deploy lambda
// 5. Test...
