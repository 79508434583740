import React, {useContext, useState, useEffect} from 'react';
import axios from "axios";
import { Pane, Paragraph } from 'evergreen-ui';

import AuthContext from '../context/AuthContext';
import MainHeader from "../components/MainHeader";
import MainTabs from "../components/MainTabs";
import MainContent from "../components/MainContent";
import Status from "../components/Status";

const HomePage = () => {
    const { user } = useContext(AuthContext);
    const [selectedTab, setSelectedTab] = useState("uploadtab");
    const [status, setStatus] = useState([]);
    const [updated, setUpdated] = useState([]);

    const onSelectTab = (tab) => {
        setSelectedTab(tab);
    }

    const handleSetStatus = (item) => {
        console.log(item);
        setStatus(status => [...status, item]);
    }

    const fetch_conversion = async (conversion_id) => {
        const res = await axios.get(`http://localhost:8000/api/conversions/${conversion_id}/`);
        return await res;
    }

    const set_conversion_status = async (conversion, status) => {
        let conversionId = conversion.data.id;
        let newData = conversion.data;
        newData.status = status;
        delete newData.id;
        const res = await axios.put(`http://localhost:8000/api/conversions/${conversionId}/`, newData);
    }

    const update_status_item = (arr, item, ix, updates) => {
        let revised_arr = [...arr];
        let updated_item = item;
        for (let k in updates) {
            updated_item[k] = updates[k];
        }
        revised_arr[ix] = updated_item;
        return [revised_arr, updated_item];
    }

    // poll for updates, every 5 minutes a max of 4 times (20 minutes total);
    // set conversion status to FAIL if no update found after 20 minutes (max lambda timeout is 15min).
    useEffect(() => {
      if (status.length > 0) {
        const checkForStatusUpdates = async () => {
          for (let i=0; i<status.length; i++) {
            let item = status[i];
            // check the DB for any changes
            let conversion = await fetch_conversion(item.conversion_id);
            // if status has changed, update it in state
            if (item.hasOwnProperty("poll_count") && item["poll_count"]+1 > 4) {
                console.log("nothing to be done");
            } else if (conversion.data.status !== item.status) {
                let [revised_arr, updated_item] = update_status_item(status, item, i, {"status": conversion.data.status, "poll_count": 5});
                setStatus(status => revised_arr);
            } else if (item.hasOwnProperty("poll_count") && item["poll_count"]+1 === 4) {
                let poll_count = item.hasOwnProperty("poll_count") ? item["poll_count"]+1 : 1;
                let [revised_arr, updated_item] = update_status_item(status, item, i, {"status": "FAIL", "poll_count": poll_count});
                setStatus(status => revised_arr);
                setUpdated(updated => [...updated, updated_item]);
                set_conversion_status(conversion, "FAIL");
            } else {
                let poll_count = item.hasOwnProperty("poll_count") ? item["poll_count"]+1 : 1;
                let [revised_arr, updated_item] = update_status_item(status, item, i, {"poll_count": poll_count});
                setStatus(status => revised_arr);
            }
          }
        }

        // const intervalID = setInterval(checkForStatusUpdates, 30000, status);
        const intervalID = setInterval(checkForStatusUpdates, 240000, status);
      }
    }, [status]);

    return (
        user ? (
        <Pane>
          <MainHeader />
          <Pane display="flex" flexDirection="row">
            <Pane 
              display="flex" 
              flexDirection="column" 
              alignItems="flex-start" 
              justifyContent="flex-start" 
              width="300px"
              minHeight="100vh"
              border="default"
            >
                <MainTabs selectedTab={selectedTab} onSelectTab={onSelectTab} />
                <Status items={status} />
            </Pane>
            <MainContent selectedTab={selectedTab} handleSetStatus={handleSetStatus} />
          </Pane>
        </Pane>
        ) : (
        <Pane>
          <Paragraph>You are not logged in - redirecting...</Paragraph>
        </Pane>
        )
    )
}

export default HomePage;