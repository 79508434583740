import React, { useState, useEffect } from "react";
import axios from 'axios';
import env from "react-dotenv";
import { Pane, Paragraph, Dialog, Text, Checkbox, Badge, Button, Strong } from 'evergreen-ui';
import ExpandablePane from "./ExpandablePane";
import Download from "./Download";
import MetadataForm from "./MetadataForm";

const Book = (props) => {
  const [metadata, setMetadata] = useState({
    title_long: "", 
    author_yomigana: "", 
    eisbn: "", 
    pisbn: "",
    ltr: false
  });

  useEffect(() => {
    setMetadata({...metadata, ...props.data});
  }, [props.data]);

  const updateMetadata = (updated) => {
    setMetadata({...metadata, ...updated});
  }

  const updateMetadataInDatabase = async (book_id) => {
    const res = await axios.post(`http://localhost:8000/api/books/${book_id}/update_book_metadata/`, metadata);
  }

  return (
    <ExpandablePane 
      expanded={false} 
      label={<LabelInner data={metadata} />} 
      content={<BookInner data={metadata} updateMetadataInDatabase={updateMetadataInDatabase} updateMetadata={updateMetadata} />} 
    />
  )
}

const ConversionBadge = (props) => {

  let color = props.status === "SUCCESS"
    ? "green"
    : props.status === "FAIL"
    ? "red"
    : "yellow";

  let created = props.created.replace(/T\d.*/, "");

  return (
    <Badge color={color}>
      {props.status}{` `}
      {created}
    </Badge>
  )
}

const LabelInner = (props) => {

  return (
    <Text display="flex" flexDirection="row" justifyContent="space-between" width="740px" lineHeight="48px">
      <Text lineHeight="48px">{props.data && props.data.title_long ? props.data.title_long : ""}</Text>
      <Text>
        {props.data.conversions && props.data.conversions.length > 0 && (
          <ConversionBadge status={props.data.conversions[0].status} created={props.data.conversions[0].created} />
        )}
        <Text lineHeight="48px" marginLeft={12}>{props.data && props.data.eisbn ? props.data.eisbn : ""}</Text>
      </Text>
    </Text>
  )
}

const BookInner = (props) => {
  const [showEdit, setShowEdit] = useState(false);

  return (
    <Pane 
      paddingLeft={32}
      paddingRight={28}
      paddingBottom={16}
    >
      <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Paragraph width="500px">Author(s): {props.data.author_yomigana}</Paragraph>
        <Paragraph width="200px" textAlign="right">{props.data.pisbn}</Paragraph>
      </Pane>
      <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Checkbox label="Reads LTR" checked={props.data.ltr} />
        <Button onClick={e => setShowEdit(true)}>Edit Metadata</Button>
      </Pane>
      <Pane >
        <Dialog
          isShown={showEdit}
          title="Edit Book Metadata"
          onCloseComplete={() => setShowEdit(false)}
          confirmLabel="Save"
          onConfirm={(close) => {
            props.updateMetadataInDatabase(props.data.id);
            close();
          }}
        >
          <MetadataForm updateMetadata={props.updateMetadata} metadata={props.data} />
        </Dialog>
      </Pane>
      {props.data.conversions && props.data.conversions.length > 0 && (
        <Conversion data={props.data.conversions[0]} />
      )}
    </Pane>
  )
}

const Conversion = (props) => {
  const [mode, setMode] = useState("local");
  const [showdownloads, setShowdownloads] = useState(false);
  const [downloading, setDownloading] = useState(false);

  let opts = [];

  for (let k in props.data.options) {
    if (props.data.options[k] === true) {
      opts.push(k);
    }
  }

  let canDownload = props.data.epub_download || props.data.pdf_download || props.data.cover_download;

  let downloads = [
    props.data.epub_download, 
    props.data.pdf_download, 
    props.data.cover_download, 
    props.data.log_download
  ];

  return (
    <Pane 
      paddingLeft={32}
      paddingRight={28}
      paddingBottom={16}
      paddingTop={12}
      marginLeft="-32px"
      marginRight="-28px"
      background="tint1"
      display="flex" 
      flexDirection="row" 
      justifyContent="space-between" 
      alignItems="flex-start"
    >
      <Pane width="350px">
        <Paragraph size={300}><Strong size={300}>Last conversion:</Strong></Paragraph>
        <Paragraph size={300}>{props.data.created} by {props.data.user}</Paragraph>
        <Paragraph size={300}>Notes: {props.data.notes}</Paragraph>
        {canDownload && (
          <Pane>
            <Paragraph marginTop={8}>
              <Button 
                disabled={downloading} 
                onClick={e => {
                  setMode("dropbox");
                  setShowdownloads(true);
                }}
              >
                {downloading ? "Saving..." : "Save to Dropbox..."}
              </Button>
            </Paragraph>
            <Paragraph marginTop={8}>
              <Button 
                disabled={downloading} 
                onClick={e => {
                  setMode("local");
                  setShowdownloads(true);
                }}
              >
                {downloading ? "Downloading..." : "Download to my computer..."}
              </Button>
            </Paragraph>
            <Download show={showdownloads} handleSetShown={setShowdownloads} handleSetDownloading={setDownloading} files={downloads} mode={mode} />
          </Pane>
        )}
      </Pane>
      <Pane width="350px">
        <Paragraph size={300}>Conversion details:</Paragraph>
        <Paragraph size={300}>Interior PDF: {props.data.interior}</Paragraph>
        <Paragraph size={300}>Cover File: {props.data.cover ? props.data.cover : "NONE"}</Paragraph>
        <Paragraph size={300}>Copyright File: {props.data.copyright ? props.data.copyright : "NONE"}</Paragraph>
        <Paragraph size={300}>Replacements: {props.data.replacements ? props.data.replacements : "NONE"}</Paragraph>
        <Paragraph size={300}>Export Options: {opts.length > 0 ? opts.join(", ") : "NONE"}</Paragraph>
      </Pane>
    </Pane>
  )
}

export default Book;
