import React, { useState } from 'react';
import { Pane, Paragraph, TextInput, CrossIcon } from 'evergreen-ui';

const Note = (props) => {

    return (
    	<Pane
    		display="flex"
    		flexDirection="row"
    		justifyContent="flex-start"
    		alignItems="center"
    	>
            <TextInput value={props.value} onChange={e => props.handleChange(e.target.value)} placeholder="Add a note (optional)" />
            <CrossIcon color="muted" marginLeft={8} size={16} onClick={e => props.handleRemoveNote()} />
        </Pane>
    )
}

export default Note;