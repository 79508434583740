import React, { useState } from 'react';
import axios from "axios";
import { Pane, Paragraph, Button, PlusIcon } from 'evergreen-ui';
import ExpandablePane from "./ExpandablePane";
import DropFile from "./DropFile";
import ConversionSettings from "./ConversionSettings";
import Note from "./Note";
import ReplacePage from "./ReplacePage";
import InsertPage from "./InsertPage";
import MetadataForm from "./MetadataForm";
import CustomPages from "./CustomPages";
import env from "react-dotenv";

const ManualUpload = (props) => {

    return (
      <ExpandablePane 
        expanded={true} 
        label="Manual Upload" 
        content={<ManualUploadInner handleTriggerConversion={props.handleTriggerConversion} />} 
      />
    )
}

const ManualUploadInner = (props) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [interior, setInterior] = useState([]);
  const [cover, setCover] = useState([]);
  const [copyright, setCopyright] = useState([]);
  const [note, setNote] = useState("");
  const [replacements, setReplacements] = useState([]);
  const [showReplacements, setShowReplacements] = useState(false);
  const [metadata, setMetadata] = useState({
    title_long: "", 
    author_yomigana: "", 
    eisbn: "", 
    pisbn: "",
    ltr: false
  });
  const [conversionSettings, setConversionSettings] = useState({
    pdfOnly: false, 
    epubOnly: false, 
    coverOnly: false, 
    noCover: false
  });

  const updateMetadata = (updated) => {
    if (updated.hasOwnProperty("eisbn")) {
      setError("");
    }
    setMetadata({...metadata, ...updated});
    console.log(metadata);
  }

  const updateConversionSettings = (updated) => {
    setConversionSettings({...conversionSettings, ...updated});
    console.log(conversionSettings);
  }

  const updateReplacements = (updated) => {
    console.log(updated);
    setReplacements([...replacements, updated]);
    console.log(replacements);
  }

  const removeReplacement = (number) => {
    let updated = replacements.filter(r => r.page !== number);
    setReplacements(updated);
    console.log(replacements);
  }

  const runConversion = async () => {
    // create book record (validating for existence first)
    const res = await axios.post(`${env.BASE_URL}/api/books/none/validate_and_create_book/`, metadata);
    console.log(res.data);
    if (res.data && res.data.status === "fail") {
      setError(res.data.message);
    }
    // create item object
    let item = {...metadata};
    if (copyright.length > 0) {
      item["copyright"] = copyright[0].path;
      item["copyright_file"] = copyright[0];
    }
    if (cover.length > 0) {
      item["cover"] = cover[0].path;
      item["cover_file"] = cover[0];
    }
    if (interior.length > 0) {
      item["interior"] = interior[0].path;
      item["interior_file"] = interior[0];
    }
    if (replacements.length > 0) {
      item["replacements"] = replacements;
    }
    // trigger conversion
    if (item.hasOwnProperty("interior") || conversionSettings.coverOnly === true) {
      props.handleTriggerConversion(item, note, conversionSettings);
    }
    // reset fields
    setMetadata({
      title_long: "", 
      author_yomigana: "", 
      eisbn: "", 
      pisbn: "",
      ltr: false
    });
    setConversionSettings({
      pdfOnly: false, 
      epubOnly: false, 
      coverOnly: false, 
      noCover: false
    });
    setInterior([]);
    setCover([]);
    setCopyright([]);
    setReplacements([]);
    setNote("");
  }

  let howmany = replacements.length > 0 ? "another" : "a";

  let metadata_disabled = !metadata.eisbn || metadata.eisbn.length > 13 || metadata.pisbn.length > 13;

  console.log(replacements);

  return (
    <Pane
      display="flex"
      flex-direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
      background="tint1"
      padding={12}
    >
      <Pane marginRight={16}>
        <DropFile label="Interior PDF..." type="pdf" handleFileData={setInterior} pdfFiles={interior} compact={true} />
        <DropFile label="Cover..." type="pdf" handleFileData={setCover} pdfFiles={cover} compact={true} />
        <DropFile label="Copyright..." type="pdf" handleFileData={setCopyright} pdfFiles={copyright} compact={true} />
        {replacements.map(r => {
          if (r.mode === "replace") {
            return <ReplacePage data={r} handleRemoveReplacement={removeReplacement} key={r.page} />
          } else {
            return <InsertPage data={r} handleRemoveReplacement={removeReplacement} key={r.page} />
          }
        })}
        <Button onClick={e => setShowReplacements(true)}><PlusIcon marginRight={12} /> Add {howmany} custom page...</Button>
        <CustomPages handleSetShown={setShowReplacements} show={showReplacements} handleAddCustomPage={updateReplacements} />
      </Pane>
      <Pane>
        {error && (<Paragraph maxWidth="340px" size={300} color="red">{error}</Paragraph>)}
        <Pane>
          <MetadataForm updateMetadata={updateMetadata} metadata={metadata} />
        </Pane>
        <ConversionSettings updateConversionSettings={updateConversionSettings} />
        <Note value={note} handleChange={setNote} handleRemoveNote={e => setNote("")} />
        <Button disabled={metadata_disabled} onClick={e => runConversion()} marginTop={12} appearance="primary" intent="success" size="large">Go</Button>
      </Pane>
    </Pane>
  )
}

export default ManualUpload;
