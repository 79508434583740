import React, { useEffect, useState } from "react";
import { Pane, Paragraph, Code, CrossIcon } from 'evergreen-ui';

const ReplacePage = (props) => {

  return (
    <Pane 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="flex-start" 
      padding={12}
      marginBottom={8}
      border="default"
    >
      <Pane
        display="flex" 
        flexDirection="row" 
        alignItems="center" 
        justifyContent="flex-start" 
        marginBottom={12}
      >
        <Paragraph>Replace page</Paragraph>
        <Code marginX={12}>{props.data.page}</Code>
        <Paragraph>with:</Paragraph>
      </Pane>
      <Pane
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Code marginX={12}>{props.data.file[0].path}</Code>
        <CrossIcon color="muted" marginLeft={8} size={16} onClick={e => props.handleRemoveReplacement(props.data.page)} />
      </Pane>
    </Pane>
  );
}

export default ReplacePage;

// {
//   number: 134,
//   filename: 123.pdf,
//   file: [File]
// }