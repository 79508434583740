import React, { Component } from "react";
import { Pane, Heading, Paragraph } from 'evergreen-ui';
import logo from '../kup-logo-lowres.png';

export default class MainHeader extends Component {

  render() {

    return (
      <Pane 
        display="flex" 
        flexDirection="row" 
        alignItems="center" 
        justifyContent="space-between" 
        height={64} 
        width="100%"
        padding={12}
        borderTop="2px solid black"
        borderBottom="2px solid black"
      >
        <Pane 
          display="flex" 
          flexDirection="row" 
          alignItems="center" 
          justifyContent="flex-start" 
        >
          <img src={logo} alt="Kodansha Logo" height="40" />
          <Heading size={600} lineHeight="40px">Conversion Tool</Heading>
        </Pane>
        <Paragraph>Search will go here...</Paragraph>
      </Pane>
    );
  }
}