import React, { useEffect, useState } from "react";
import axios from "axios";
import { Pane, Paragraph, Pagination } from 'evergreen-ui';
import Book from "./Book";

const Books = (props) => {
  const [load, setLoad] = useState(true);
  const [count, setCount] = useState(0);
  const [books, setBooks] = useState([]);
  const [page, setPage] = useState(1);

  const getBookCount = async () => {
    const res = await axios.post(`http://localhost:8000/api/books/none/count/`);
    return await res;
  }

  const getBooks = async (num, filters) => {
    // https://api.example.org/accounts/?page=4
    const res = await axios.get(`http://localhost:8000/api/books/?page=${num}`, filters);
    return await res;
  }

  const getBookConversions = async (book_id) => {
    const res = await axios.post(`http://localhost:8000/api/conversions/none/get_book_conversions/`, {book: book_id});
    return await res;
  }

  useEffect(() => {
    if (load === true) {
      async function fetchCount() {
        const res = await getBookCount();
        if (res && res.status === 200) {
          setCount(res.data.count);
          const book_res = await getBooks(1, {});
          if (book_res && book_res.status === 200) {
            setBooks(book_res.data.results);
          }
        }
      }
      fetchCount();
      setLoad(false);
    }
  }, [count, books, load]);

  const setPageAndLoadBooks = async (num) => {
    setPage(num);
    const book_res = await getBooks(num, {});
    if (book_res && book_res.status === 200) {
      console.log(book_res.data.results);
      setBooks(book_res.data.results);
    }
  }

  return (
    <Pane 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="flex-start" 
      padding={12}
    >
      {books.map(book => <Book key={book.eisbn} data={book} />)}
      <Pagination page={page} totalPages={Math.ceil(count/20)} onPageChange={num => setPageAndLoadBooks(num)}></Pagination>
    </Pane>
  );
}

export default Books;