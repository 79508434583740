import React, { useState, useEffect } from 'react';
import { Pane, Paragraph, Checkbox } from 'evergreen-ui';

const ConversionSettings = (props) => {
    const [pdfChecked, setPdfChecked] = useState(false);
    const [epubChecked, setEpubChecked] = useState(false);
    const [coverChecked, setCoverChecked] = useState(false);
    const [noCoverChecked, setNoCoverChecked] = useState(false);

    return (
    	<Pane>
            <Checkbox 
                label="Export PDF only" 
                checked={pdfChecked} 
                onChange={e => {
                    props.updateConversionSettings({
                        pdfOnly: e.target.checked,
                        epubOnly: false,
                        coverOnly: false
                    });
                    setPdfChecked(e.target.checked);
                    setEpubChecked(false);
                    setCoverChecked(false);
                }}
            />
            <Checkbox 
                label="Export EPUB only" 
                checked={epubChecked} 
                onChange={e => {
                    props.updateConversionSettings({
                        pdfOnly: false,
                        epubOnly: e.target.checked,
                        coverOnly: false
                    });
                    setPdfChecked(false);
                    setEpubChecked(e.target.checked);
                    setCoverChecked(false);
                }}
            />
            <Checkbox 
                label="Export cover only" 
                checked={coverChecked} 
                onChange={e => {
                    props.updateConversionSettings({
                        pdfOnly: false,
                        epubOnly: false,
                        coverOnly: e.target.checked
                    });
                    setPdfChecked(false);
                    setEpubChecked(false);
                    setCoverChecked(e.target.checked);
                }}
            />
            <hr />
            <Checkbox 
                label="Export without cover" 
                checked={noCoverChecked} 
                onChange={e => {
                    props.updateConversionSettings({noCover: e.target.checked});
                    setNoCoverChecked(e.target.checked);
                }}
            />
        </Pane>
    )
}

export default ConversionSettings;