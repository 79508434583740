import React, {useContext} from 'react';
import AuthContext from '../context/AuthContext';
import { Pane, Heading } from 'evergreen-ui';

const LoginPage = () => {

    let {loginUser} = useContext(AuthContext);

    return (
        <Pane 
            className="fullScreenWrapper"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Heading marginBottom={12}>Please Log In:</Heading>
            <form className="authform" onSubmit={loginUser}>
                <input type="text" name="username" placeholder="Enter username"/><br />
                <input type="password" name="password" placeholder="Enter password"/><br />
                <input type="submit"/>
            </form>
        </Pane>
    )
}

export default LoginPage;